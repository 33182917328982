import React, { useState } from 'react';
import { FlexBox, Typography } from '@helloworld1812/ws-components';
import { useTheme } from 'styled-components';
import { ConfigProvider } from 'antd';
import antd4_EN_US from 'antd/es/locale/en_US';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import {
  HiringPaywall,
  HiringPaywallModal,
  useHiringPaywall,
  useIsLiteTier,
} from '@/components/tier-lite/hiring-paywall';
import AppThemeProvider from '@/app-theme-provider';

import { S } from './hiring-paywall-wrapper.styles';
import {
  HiringPaywallWrapperProps,
  PureWrapperProps,
  FunctionModulWrapperProps,
  BooleanWrapperProps,
} from './hiring-paywall-wrapper.types';

const queryClient = new QueryClient();

const PureWrapper: React.FC<React.PropsWithChildren<PureWrapperProps>> = ({
  children,
  visible,
  mode = 'paywall',
  block = false,
  disabledStyle = 'none',
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const theme = useTheme();

  if (visible) {
    if (mode === 'hidden') {
      return null;
    }
    if (mode === 'hover') {
      return (
        <>
          <S.Popover
            visible={isPopoverVisible && !isModalVisible}
            onVisibleChange={setPopoverVisible}
            content={
              <FlexBox direction="column" gap={8}>
                <Typography fontWeight="bold" color="textPrimary">
                  Feature not included in Lite Tier
                </Typography>
                <Typography color="textSecondary">Want to see it in action? Get in touch with us! </Typography>
                <S.LinkText color="textLink">
                  <div onClick={() => setModalVisible(true)}>Contact us for a demo</div>
                </S.LinkText>
              </FlexBox>
            }
            trigger="hover"
            placement="bottom"
            zIndex={theme.zIndex.snackbar + 1}
          >
            <S.HoverCardWrapper className="hiring-paywall-wrapper" block={block} disabledStyle={disabledStyle}>
              {children}
              <S.GrayOutWrapper />
            </S.HoverCardWrapper>
          </S.Popover>
          {isModalVisible && (
            <HiringPaywallModal closeIcon visible={isModalVisible} onCancel={() => setModalVisible(false)} />
          )}
        </>
      );
    }
    return <HiringPaywall />;
  }
  return <>{children}</>;
};

const FunctionModulWrapper = (props: FunctionModulWrapperProps) => {
  const { hasNoFunctionModule, ...wrapperProps } = props;
  const { isVisible } = useHiringPaywall(hasNoFunctionModule);

  return <PureWrapper {...wrapperProps} visible={isVisible} />;
};

const BooleanWrapper = (props: BooleanWrapperProps) => {
  const { hasNoFunctionModule, ...wrapperProps } = props;
  const isLiteTier = useIsLiteTier();

  return <PureWrapper {...wrapperProps} visible={hasNoFunctionModule && isLiteTier} />;
};

export const HiringPaywallWrapper: React.FC<React.PropsWithChildren<HiringPaywallWrapperProps>> = (props) => {
  const { hasNoFunctionModule, ...wrapperProps } = props;

  return (
    <ConfigProvider locale={antd4_EN_US} prefixCls="ant4" iconPrefixCls="ant4icon">
      <AppThemeProvider>
        <QueryClientProvider client={queryClient}>
          {typeof hasNoFunctionModule === 'boolean' ? (
            <BooleanWrapper hasNoFunctionModule={hasNoFunctionModule} {...wrapperProps} />
          ) : (
            <FunctionModulWrapper hasNoFunctionModule={hasNoFunctionModule} {...wrapperProps} />
          )}
        </QueryClientProvider>
      </AppThemeProvider>
    </ConfigProvider>
  );
};
